import React, { useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../../atoms/Button/Button';
import { css } from '@emotion/react';
import getUrlParameter from '../../atoms/Utm/Utm';
import Text from '../../atoms/Text/Text';
import ReCAPTCHA from 'react-google-recaptcha';
// import { navigate } from "gatsby"

export default function CotizarForm({
  siteUrl,
  nomProyecto,
  codInmueble,
  modelo,
  correoEvolta,
  paginaGracias,
  dormitorios,
  banios,
  precio,
}) {
  const formikRef = useRef(null);
  const recaptchaRef = useRef(null);
  const [token, setToken] = useState(false);
  const [viewError, setViewError] = useState(false);

  let utm_campaign = getUrlParameter('utm_campaign')
    ? getUrlParameter('utm_campaign')
    : '';
  let utm_content = getUrlParameter('utm_content')
    ? getUrlParameter('utm_content')
    : '';
  let utm_medium = getUrlParameter('utm_medium')
    ? getUrlParameter('utm_medium')
    : '';
  let utm_source = getUrlParameter('utm_source')
    ? getUrlParameter('utm_source')
    : '';
  let utm_term = getUrlParameter('utm_term') ? getUrlParameter('utm_term') : '';

  const formGroupCss = css`
    &.form-group {
      @media (max-width: 767px) {
        margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
      }
    }

    & select {
      background-position: top calc(100vw * (calc(18 / var(--width_base))))
        right calc(100vw * (calc(12 / var(--width_base))));
      @media (max-width: 767px) {
        background-position: top calc(100vw * (calc(13 / var(--width_base))))
          right calc(100vw * (calc(12 / var(--width_base))));
      }
    }
  `;

  const buttonCss = css`
    width: 100%;
    margin-top: 0;
    @media (max-width: 767px) {
      margin-top: 0;
    }
  `;

  const formAceptCss = css`
    &.form-acept {
      margin-top: 0;
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(18 / var(--width_base))));
      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(14 / var(--width_base))));
        line-height: calc(100vw * (calc(18 / var(--width_base))));
      }
    }
  `;

  const formNroDoc = css`
    display: flex;
  `;

  const formNroDocLeft = css`
    width: calc(100vw * (calc(150 / var(--width_base))));
    flex-shrink: 0;
  `;

  const formNroDocRight = css`
    width: 100%;
    margin-left: calc(100vw * (calc(1 / var(--width_base))));
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(25 / var(--width_base))));
    line-height: calc(100vw * (calc(25 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(24 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const boldText = css`
    font-weight: bold;
    text-decoration: underline;
  `;

  const [envio, setEnvio] = useState('');

  const onChange = () => {
    setToken(recaptchaRef.current.getValue());
    // const token = await recaptchaRef.current.executeAsync();
  };

  return (
    <div>
      <Text
        data="INGRESA TUS DATOS"
        styleCss={titleCss}
        colorText="#8cb036"
        align="center"
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          TipoDocumento: 'DNI',
          NroDocumento: '',
          Nombre: '',
          Apellidos: '',
          Telefono: '',
          Email: '',
          Acepto_condiciones: '',
        }}
        validate={(values) => {
          // console.log("token",token)
          setViewError(true);
          const errors = {};
          const regexNumber = /^[0-9]+$/;
          if (!values.TipoDocumento) errors.TipoDocumento = 'Campo requerido';
          if (!values.NroDocumento) errors.NroDocumento = 'Campo requerido';
          else if (!values.NroDocumento.match(regexNumber))
            errors.NroDocumento = 'Solo numeros';

          if (
            values.TipoDocumento === 'DNI' &&
            values.NroDocumento.length !== 8
          )
            errors.NroDocumento = 'DNI debe tener 8 digitos';

          if (!values.Nombre) errors.Nombre = 'Campo requerido';
          if (!values.Apellidos) errors.Apellidos = 'Campo requerido';
          if (!values.Telefono) errors.Telefono = 'Campo requerido';
          if (!/^\d{9}$/.test(values.Telefono)) {
            errors.Telefono = 'Telefono invalido';
          }
          if (!values.Email) errors.Email = 'Campo requerido';
          else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
          )
            errors.Email = 'Correo Invalido';
          // const token = await recaptchaRef.current.executeAsync();
          // console.log("token",token);
          // if (!token) errors.recaptcha = 'Es necesario validar el captcha';
          if (!values.Acepto_condiciones)
            errors.Acepto_condiciones = 'Campo requerido';
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const comentario = `Se cotizo por web lo siguiente: Modelo: ${modelo} Precio: ${precio} N° Dormitorios: ${dormitorios} N° Baños: ${banios}`;

            const formdata = new FormData();
            formdata.append('TipoDocumento', values.TipoDocumento);
            formdata.append('NroDocumento', values.NroDocumento);
            formdata.append('Nombre', values.Nombre);
            formdata.append('Apellidos', values.Apellidos);
            formdata.append('Telefono', values.Telefono);
            formdata.append('Email', values.Email);
            formdata.append('Proyecto', nomProyecto);
            formdata.append('Modelo', modelo);
            formdata.append('CorreoEvolta', correoEvolta);
            formdata.append('CodInmueble', codInmueble);
            formdata.append('UtmSource', utm_source);
            formdata.append('UtmMedium', utm_medium);
            formdata.append('UtmCampaign', utm_campaign);
            formdata.append('UtmTerm', utm_term);
            formdata.append('Comentario', comentario);
            formdata.append('UtmContent', utm_content);
            formdata.append('_wpcf7_unit_tag', 'wpcf7-f1328-p6925-o1');

            fetch(
              `${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/1328/feedback`,
              {
                method: 'POST',
                body: formdata,
              }
            )
              .then((res) => res.json())
              .then((json) => {
                if (json.status === 'mail_sent') {
                  setEnvio(true);
                  setSubmitting(false);
                  resetForm();
                  setToken(false);
                  setViewError(false);
                  // // navigate(paginaGracias);
                  window.location.href = siteUrl + paginaGracias;
                } else {
                  setEnvio(false);
                }
              });
          } catch (error) {
            console.error(error);
            setEnvio(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div
              className="form-group"
              css={css`
                ${formGroupCss};
                ${formNroDoc}
              `}
            >
              <div css={formNroDocLeft}>
                <Field
                  name="TipoDocumento"
                  as="select"
                  className="form-control"
                >
                  <option value="DNI">DNI</option>
                  <option value="RUC">RUC</option>
                  <option value="CÉDULA DE IDENTIDAD">
                    CÉDULA DE IDENTIDAD
                  </option>
                  <option value="CARNET DE EXTRANJERÍA">
                    CARNET DE EXTRANJERÍA
                  </option>
                  <option value="PASAPORTE">PASAPORTE</option>
                </Field>
                <ErrorMessage
                  className="form-error"
                  name="TipoDocumento"
                  component="div"
                />
              </div>
              <div css={formNroDocRight}>
                <div css={formGroupCss}>
                  <Field
                    className="form-control"
                    type="text"
                    name="NroDocumento"
                    id="NroDocumento"
                    placeholder="N° Documento de Identidad*"
                  />
                  <ErrorMessage
                    className="form-error"
                    name="NroDocumento"
                    component="div"
                  />
                </div>
              </div>
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field
                className="form-control"
                type="text"
                name="Nombre"
                id="Nombre"
                placeholder="Nombre*"
              />
              <ErrorMessage
                className="form-error"
                name="Nombre"
                component="div"
              />
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field
                className="form-control"
                type="text"
                name="Apellidos"
                id="Apellidos"
                placeholder="Apellidos*"
              />
              <ErrorMessage
                className="form-error"
                name="Apellidos"
                component="div"
              />
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field
                className="form-control"
                type="text"
                name="Telefono"
                id="Telefono"
                placeholder="Teléfono*"
              />
              <ErrorMessage
                className="form-error"
                name="Telefono"
                component="div"
              />
            </div>
            <div className="form-group" css={formGroupCss}>
              <Field
                className="form-control"
                type="email"
                name="Email"
                id="Email"
                placeholder="Email*"
              />
              <ErrorMessage
                className="form-error"
                name="Email"
                component="div"
              />
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              onChange={onChange}
              sitekey="6LfSZ8spAAAAAOzPJF8gWig4ZoSkOF0SXEhUpF8f"
            />
            {viewError && (
              <>
                {!token && (
                  <div className="form-error" style={{ margin: '7px' }}>
                    Falta validar captcha
                  </div>
                )}
              </>
            )}
            <div className="form-group text-center">
              <label
                className="form-acept"
                css={formAceptCss}
                htmlFor="Acepto_condiciones"
              >
                <Field
                  className="accept-box"
                  type="checkbox"
                  name="Acepto_condiciones"
                  id="Acepto_condiciones"
                />
                &nbsp;&nbsp;&nbsp;He leído y acepto todos los{' '}
                <a
                  href="/terminos-y-condiciones/"
                  target="_blank"
                  rel="noreferrer"
                  css={boldText}
                >
                  términos y condiciones
                </a>{' '}
                y la{' '}
                <a
                  href="/terminos-y-condiciones/"
                  target="_blank"
                  rel="noreferrer"
                  css={boldText}
                >
                  política de privacidad
                </a>{' '}
                de la empresa INMOBILIARIA FUNDAMENTA
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Acepto_condiciones"
                component="div"
              />
            </div>
            <div className="text-center" css={formGroupCss}>
              <Button
                typeEle="submit"
                styleTypeEle="submit"
                disabled={isSubmitting}
                styleEle={buttonCss}
              >
                ENVIAR COTIZACION
              </Button>
            </div>
            {envio === false && envio !== '' && (
              <div className="">Error en el envio</div>
            )}
            {envio === true && envio !== '' && (
              <div className="">Correo enviado</div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
